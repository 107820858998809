import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
const OurServices = () => {
  return (
    <div>
      {/* <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Why Choose Fast Drop Taxi
?        </h4>
        <div className="flex flex-col gap-y-[10px] pt-4">
          <h3 className="font-semibold text-3xl  sm:text-2xl">Convenience:</h3>
          <p>
            At Fast Drop Taxi
            , we are committed to providing efficient and
            reliable drop taxi services for our customers. We understand the
            importance of punctuality and convenience when it comes to drop taxi
            services, and that is why we strive to make every journey with us a
            hassle-free and comfortable experience.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Reliability:</h3>
          <p>
            We understand the importance of punctuality and reliability when it comes to transportation. That's why we have a fleet of well-maintained vehicles and a team of professional drivers committed to getting you to your destination on time, every time.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Affordability:</h3>
          <p>
            Quality transportation shouldn't break the bank. At Fast Drop Taxi
, we offer competitive rates and transparent pricing with no hidden fees. Enjoy the convenience of our drop taxi services without worrying about excessive costs.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Safety:</h3>
          <p>
            Your safety is our top priority. All our drivers undergo rigorous background checks and extensive training to ensure they meet the highest safety standards. Additionally, our vehicles are regularly inspected and sanitized to provide you with a clean and secure travel experience.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Customer Satisfaction:</h3>
          <p>
            We value your feedback and strive to exceed your expectations with every ride. Our dedicated customer support team is available 24/7 to assist you with any inquiries or special requests, ensuring your journey with Fast Drop Taxi
 is nothing short of exceptional.                        </p>
        </div>



      </div> */}
            <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
            <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">Our Services
            </h4>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Intercity Drop Taxi:</h3>
              <p>
              Fast Drop Taxi offers convenient intercity drop services, connecting you to major cities and towns across Tamil Nadu. Travel from Chennai to Coimbatore, Madurai to Tiruchirappalli, and more with ease.    
              </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Airport Transfers:</h3>
              <p>
Enjoy hassle-free airport transfers to and from Chennai, Coimbatore, Madurai, and Tiruchirappalli airports. Our punctual service ensures you reach your destination on time, every time.    
              </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Outstation Trips:</h3>
              <p>
Planning a weekend getaway or a business trip outside Tamil Nadu? Our outstation taxi services are available at competitive rates, offering you the flexibility to travel anywhere in South India.              </p>
            </div>
                        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Hourly Rentals:</h3>
              <p>
              Need a taxi for a few hours? Our hourly rental service allows you to book a cab for short durations, perfect for city tours, shopping, or attending meetings.         
              </p>   
              </div>
              <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Corporate Services:</h3>
              <p>
              Fast Drop Taxi provides tailored transportation solutions for businesses. Whether it's daily employee transport, executive travel, or corporate events, we have you covered.              </p>   
              </div>
        
          </div>
    
          <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        Why Choose Us?
        </h4>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Experienced Drivers:</h3>
          <p>
          Our drivers are professional, courteous, and knowledgeable about the local routes.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">24/7 Availability:</h3>
          <p>
          We operate around the clock to cater to your travel needs anytime, anywhere.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Transparent Pricing:</h3>
          <p>
          No hidden charges, just straightforward and affordable rates. </p>
        </div>
                <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Comfort & Safety:</h3>
          <p>
          Our fleet consists of well-maintained, air-conditioned vehicles equipped with the latest safety features. </p>
        </div>
          <p className="pt-4">
          Round-the-clock service ensures that you can travel at any time, day or night.          </p>
      {/* </div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
        Why You Choose Us?      </h4>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Wide Range of Destinations:</h3>
          <p>
          Whether traveling to Chennai, Coimbatore, Madurai, Trichy, or any other city within Tamil Nadu, Fast Drop Taxi
 provides comprehensive coverage across the state, connecting various towns and cities efficiently.

          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Comfortable and Well-Maintained Vehicles:</h3>
          <p>
          The fleet includes a variety of vehicles, from compact cars to SUVs, ensuring a comfortable journey for all passengers. Each vehicle is regularly maintained and cleaned, ensuring a pleasant travel experience.
          </p>
        </div>
        <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Professional and Experienced Drivers:</h3>
          <p>
          Fast Drop Taxi
 prides itself on its team of professional drivers who are not only experienced and skilled but also courteous and knowledgeable about the local routes, ensuring a safe and smooth journey.          </p>
        </div>
                <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Affordable Pricing:</h3>
          <p>
          With transparent pricing and no hidden charges, Fast Drop Taxi
 offers competitive rates for all their services. The focus is on providing value for money, making one-way travel affordable and accessible.
          </p>
        </div>
                <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">Easy Booking Process</h3>
          <p>
          The booking process is straightforward and user-friendly. Customers can book a cab through the website, mobile app, or by calling the customer service team. Flexible payment options are available for convenience.          </p>
        </div>
 */}
         <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
          <h3 className="font-semibold text-2xl">For bookings and inquiries, reach out to us at:</h3>
          <div><span className="text-xl font-semibold">Website:</span><a href="/" className="pl-3">www.fastdroptaxi.com</a></div> 
          <div><span className="text-xl font-semibold">Phone:</span><a href="tel:+919514599929" className="pl-4">+91 96265 99929</a></div>        
          <div><span className="text-xl font-semibold">Email:</span><a href="mailto:taxifastdrop@gmail.com" className="pl-5">taxifastdrop@gmail.com</a></div>        

         </div>

      </div>
      
      <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Easy Online Booking
            </p>
            <p>Pickup and Drop on time</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Professional Drivers
            </p>
            <p>Well Trained years of experience</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Variety of Prime Brands
            </p>
            <p>Seden,Innova,Etios -Etc</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Secure Online Payment
            </p>
            <p>24 /7 Customer Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
